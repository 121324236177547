import "./src/assets/scss/main.scss"
//import "./src/assets/js/utils.js"
//import "./src/assets/style.css"
// import "./src/assets/style-rtl.css"
//import "./src/assets/print.css"
//import "./src/assets/style-theme.css"
import Typesense from 'typesense';

// Initialize WeGlot library here
const initializeWeGlot = () => {        
    const weglotScript = document.createElement('script');
    weglotScript.src = "https://cdn.weglot.com/weglot.min.js";
    
    weglotScript.onload = () => {
        
        Weglot.initialize({
            api_key: 'wg_889ba0be108a1a554453eb0bebb093ce2'
           
        });

        let client = new Typesense.Client({
        'nearestNode': {'host': '1zbc0wgx2o34tu5ep.a1.typesense.net', 'port': '443', 'protocol': 'https'},
        'nodes': [
            {'host': '1zbc0wgx2o34tu5ep-1.a1.typesense.net', 'port': '443', 'protocol': 'https'},
            {'host': '1zbc0wgx2o34tu5ep-2.a1.typesense.net', 'port': '443', 'protocol': 'https'},
            {'host': '1zbc0wgx2o34tu5ep-3.a1.typesense.net', 'port': '443', 'protocol': 'https'},
        ],
        'apiKey': 'SoM8ZWZBv3BtJ0DWPLGC7LsEahnMycLn',
        'connectionTimeoutSeconds': 2
        });

        // Weglot.on("switchersReady", function() {
        //     console.log('we-glot-init');
        //     var myDiv = document.getElementById("weglot-listbox");
        //     setTimeout(function(){
        //         myDiv.style.opacity = '1';
        //     }, 300);
        // });
           
        // ReadSpeaker & Parse Content
        if (Weglot.initialized) {
            var options = Weglot.options;
            var currentLang = Weglot.getCurrentLang();            
            // console.log(currentLang);
            if (process.env.SITE_URL === 'https://normalnolimits.com') {
                setTimeout(function(){
                    const div = document.querySelector('div#GatsbyBody');
                    const main = document.querySelector('main');
                    if (div && main) {
                        var postTaxonomyElement = document.getElementById('postTaxonomy');
                        var postTaxonomy = postTaxonomyElement ? postTaxonomyElement.textContent : '';       
                        //console.log(postTaxonomy);
                        //console.log(currentLang);
                        if(postTaxonomy || div.classList.contains('home') || div.classList.contains('template-property-listing') || div.classList.contains('landing') || div.classList.contains('search-results') || main.classList.contains('notFound') || div.classList.contains('blog') || div.classList.contains('our-locations')) {

                        } else {
                            //console.log('test');
                            var lang = document.documentElement.lang;
                            console.log(lang);
                            var postTypeElement = document.getElementById('postType');
                            var postType = postTypeElement ? postTypeElement.textContent : '';

                            var pageIDElement = document.getElementById('pageID');
                            var pageID = pageIDElement ? pageIDElement.textContent : '';

                            var pageTitleElement = document.getElementById('pageTitle');
                            var pageTitle = pageTitleElement ? pageTitleElement.textContent : '';

                            var headings = '';
                            var bodyContent = '';
                            var locationDescription = '';

                            var locationAddressElement = document.getElementById('locationAddress');
                            var locationAddress = locationAddressElement ? locationAddressElement.textContent : '';

                            var locationPhoneElement = document.getElementById('locationPhone');
                            var locationPhone = locationPhoneElement ? locationPhoneElement.textContent : '';

                            var propertyLocationElement = document.getElementById('propertyLocation');
                            var propertyLocation = propertyLocationElement ? propertyLocationElement.textContent : '';

                            var heroImageElement = document.getElementById('pageHeroImage');
                            var heroImage = heroImageElement ? heroImageElement.textContent : '';

                            var datePublishedElement = document.getElementById('datePublished');
                            var datePublished = datePublishedElement ? parseInt(datePublishedElement.textContent) : 0;

                            var dateModifiedElement = document.getElementById('dateModified');
                            var dateModified = dateModifiedElement ? parseInt(dateModifiedElement.textContent) : 0;

                            var pageUrl = window.location.href;
                            pageUrl = pageUrl.split('?')[0];

                            var readTimeElement = document.getElementById('readTime');
                            var readTime = readTimeElement ? readTimeElement.textContent : '';

                            var longitudeElement = document.getElementById('longitude');
                            var longitude = longitudeElement ? longitudeElement.textContent : '';

                            var latitudeElement = document.getElementById('latitude');
                            var latitude = latitudeElement ? latitudeElement.textContent : '';

                            var introTextElement = document.getElementById('propertyIntroText');
                            var introText = introTextElement ? introTextElement.textContent : '';

                            var tagsElement = document.getElementById('tags');
                            var tags = tagsElement ? tagsElement.textContent : '';
                            var tagsArray = tags.split(",");

                            var locationAddressElement = document.getElementById('locationAddress');
                            var locationAddress = locationAddressElement ? locationAddressElement.textContent : '';

                            var locationPhoneElement = document.getElementById('locationPhone');
                            var locationPhone = locationPhoneElement ? locationPhoneElement.textContent : '';

                            var bedroomsElement = document.getElementById('propertyBedrooms');
                            var bedrooms = bedroomsElement ? bedroomsElement.textContent : '';

                            var bathroomsElement = document.getElementById('propertyBathrooms');
                            var bathrooms = bathroomsElement ? bathroomsElement.textContent : '';

                            var carspacesElement = document.getElementById('propertyCarspaces');
                            var carspaces = carspacesElement ? carspacesElement.textContent : '';

                            var vacanciesElement = document.getElementById('propertyVacancies');
                            var vacancies = vacanciesElement ? vacanciesElement.textContent : '';

                            var silRefElement = document.getElementById('silReference');
                            var silRef = silRefElement ? silRefElement.textContent : '';

                            var secTitleElement = document.getElementById('secTitle');
                            var secTitle = secTitleElement ? secTitleElement.textContent : '';

                            var propertyTypesElement = document.getElementById('propertyTypes');
                            var propertyTypes = propertyTypesElement ? propertyTypesElement.textContent : '';
                            var propertyTypesArray = propertyTypes.split(",");

                            var propertyStatesElement = document.getElementById('propertyStates');
                            var propertyStates = propertyStatesElement ? propertyStatesElement.textContent : '';
                            var propertyStatesArray = propertyStates.split(",");

                            var propertyFeaturesElement = document.getElementById('propertyFeatures');
                            var propertyFeatures = propertyFeaturesElement ? propertyFeaturesElement.textContent : '';
                            var propertyFeaturesArray = propertyFeatures.split(",");

                            var accessibilityFeaturesElement = document.getElementById('propertyAccessibilityFeatures');
                            var accessibilityFeatures = accessibilityFeaturesElement ? accessibilityFeaturesElement.textContent : '';
                            var accessibilityFeaturesArray = accessibilityFeatures.split(",");

                            var lifestyleFeaturesElement = document.getElementById('propertyLifestyleFeatures');
                            var lifestyleFeatures = lifestyleFeaturesElement ? lifestyleFeaturesElement.textContent : '';
                            var lifestyleFeaturesArray = lifestyleFeatures.split(",");

                            var additionalFeaturesElement = document.getElementById('propertyAdditionalFeatures');
                            var additionalFeatures = additionalFeaturesElement ? additionalFeaturesElement.textContent : '';
                            var additionalFeaturesArray = additionalFeatures.split(",");

                            var additionalFeaturesColourElement = document.getElementById('propertyAdditionalFeaturesColour');
                            var additionalFeaturesColour = additionalFeaturesColourElement ? additionalFeaturesColourElement.textContent : '';

                            var additionalFeaturesTitleElement = document.getElementById('propertyAdditionalFeaturesTitle');
                            var additionalFeaturesTitle = additionalFeaturesTitleElement ? additionalFeaturesTitleElement.textContent : '';

                            //console.log(propertyTypesArray);

                            if(readTime) {
                                readTime = readTime.textContent;
                            } else {
                                readTime = '';
                            }
                            var region = document.getElementById('propertyRegion');
                            if(region) {
                                region = region.textContent;
                            } else {
                                region = '';
                            }
                            /*var headingsTwo = document.getElementsByTagName("h2");
                            for (let i = 0; i < headingsTwo.length; i++) {
                                headings += ' '+headingsTwo[i].textContent;
                            }
                            var paragraphs = document.getElementsByTagName("p");
                            for (let i = 0; i < paragraphs.length; i++) {
                                headings += ' '+paragraphs[i].textContent;
                            }*/
                            document.querySelectorAll('.contentBlocks section').forEach( section => {
                                //console.log(section);
                                if(section.classList.contains('nextSteps') || section.classList.contains('searchSuggestions') || section.classList.contains('cta-module')) {

                                } else {
                                    //console.log('test');
                                    var headingsTwo = section.querySelectorAll('h2');
                                    for (let i = 0; i < headingsTwo.length; i++) {
                                        bodyContent += ' '+headingsTwo[i].textContent;
                                    }
                                    var headingsThree = section.querySelectorAll('h3');
                                    for (let i = 0; i < headingsThree.length; i++) {
                                        bodyContent += ' '+headingsThree[i].textContent;
                                    }
                                    var headingsFour = section.querySelectorAll('h4');
                                    for (let i = 0; i < headingsFour.length; i++) {
                                        bodyContent += ' '+headingsFour[i].textContent;
                                    }
                                    var paragraphs = section.querySelectorAll("p");
                                    for (let i = 0; i < paragraphs.length; i++) {
                                        bodyContent += ' '+paragraphs[i].textContent;
                                    }
                                }
                            });

                            if(postType == 'resources') {
                                document.querySelectorAll('section.resourcesListing .container .resourcesOuter span.item span.desc').forEach( section => {
                                    bodyContent += ' '+section.textContent;
                                });
                            }

                            var locationDescriptions = document.querySelectorAll("main.location section.banner span.textBlock .container span.inner p.description");
                            //console.log(locationDescriptions);
                            for (let i = 0; i < locationDescriptions.length; i++) {
                                //console.log(locationDescriptions[i].textContent);
                                locationDescription = locationDescriptions[i].textContent;
                            }

                            /*var locationAddressBlock = document.getElementById('locationAddress');
                            if(locationAddressBlock) {
                                locationAddress = locationAddressBlock.textContent;
                            }

                            var locationPhoneBlock = document.getElementById('locationPhone');
                            if(locationPhoneBlock) {
                                locationPhone = locationPhoneBlock.textContent;
                            }*/

                            //console.log(locationAddress);
                            //console.log(locationPhone);

                            //console.log(bodyContent);
                            //console.log(locationDescription);
                            //console.log(pageUrl);
                            //console.log(lang);
                            //console.log(postType);

                            /*if(postType == 'page' || postType == 'post' || postType == 'property') {*/
                            if(postType == 'page' || postType == 'post' || postType == 'property' || postType == 'location' || postType == 'resources') {
                                let document = {
                                    'id': pageID,
                                    'post_type': postType,
                                    'permalink': pageUrl,
                                    'page_datetime': datePublished,
                                    'page_modified': dateModified,
                                    'page_title': pageTitle,
                                    'page_content': bodyContent,
                                    'short_description': introText,
                                    'read_time': readTime,
                                    'region': region,
                                    'hero_image': heroImage,
                                    'sil_reference': silRef,
                                    'secondary_title': secTitle,
                                    'longitude': longitude,
                                    'latitude': latitude,
                                    'tag': tagsArray,
                                    'location_address': locationAddress,
                                    'location_phone': locationPhone,
                                    'bedrooms': bedrooms,
                                    'bathrooms': bathrooms,
                                    'car_spaces': carspaces,
                                    'vacancies': vacancies,
                                    'property_type': propertyTypesArray,
                                    'property_feature': propertyFeaturesArray,
                                    'accessibility_feature': accessibilityFeaturesArray,
                                    'lifestyle_feature': lifestyleFeaturesArray,
                                    'supported_by': additionalFeaturesArray,
                                    'support_by_colour': additionalFeaturesColour,
                                    'support_by_title': additionalFeaturesTitle,
                                    'property_location': propertyLocation,
                                    'property_state': propertyStatesArray
                                }
                                var currentLang = Weglot.getCurrentLang();
                                //console.log(currentLang);
                                //console.log(lang);
                                if(currentLang == 'es') {
                                    client.collections('pages-es').documents().upsert(document)
                                } else if(currentLang == 'en') {
                                    client.collections('pages').documents().upsert(document)
                                } else if(currentLang == 'it') {
                                    client.collections('pages-it').documents().upsert(document)
                                } else if(currentLang == 'el') {
                                    client.collections('pages-el').documents().upsert(document)
                                } else if(currentLang == 'fl') {
                                    client.collections('pages-fl').documents().upsert(document)
                                } else if(currentLang == 'hi') {
                                    client.collections('pages-hi').documents().upsert(document)
                                } else if(currentLang == 'vi') {
                                    client.collections('pages-vi').documents().upsert(document)
                                } else if(currentLang == 'pa') {
                                    client.collections('pages-pa').documents().upsert(document)
                                } else if(currentLang == 'ar') {
                                    client.collections('pages-ar').documents().upsert(document)
                                }
                            }
                        }
                    }
                }, 500);
            }
             
        } else {
            Weglot.on("initialized", function() {        
                var options = window.Weglot.options;
                var currentLang = window.Weglot.getCurrentLang();                
                // console.log(currentLang);
                if (process.env.SITE_URL === 'https://normalnolimits.com') {

                    setTimeout(function(){
                        const div = document.querySelector('div#GatsbyBody');
                        const main = document.querySelector('main');
                        if (div && main) {
                            var postTaxonomyElement = document.getElementById('postTaxonomy');
                            var postTaxonomy = postTaxonomyElement ? postTaxonomyElement.textContent : '';       
                            //console.log(postTaxonomy);
                            //console.log(currentLang);
                            if(postTaxonomy || div.classList.contains('home') || div.classList.contains('template-property-listing') || div.classList.contains('landing') || div.classList.contains('search-results') || main.classList.contains('notFound') || div.classList.contains('blog') || div.classList.contains('our-locations')) {

                            } else {
                                //console.log('test');
                                var lang = document.documentElement.lang;
                                console.log(lang);
                                var postTypeElement = document.getElementById('postType');
                                var postType = postTypeElement ? postTypeElement.textContent : '';

                                var pageIDElement = document.getElementById('pageID');
                                var pageID = pageIDElement ? pageIDElement.textContent : '';

                                var pageTitleElement = document.getElementById('pageTitle');
                                var pageTitle = pageTitleElement ? pageTitleElement.textContent : '';

                                var headings = '';
                                var bodyContent = '';
                                var locationDescription = '';

                                var locationAddressElement = document.getElementById('locationAddress');
                                var locationAddress = locationAddressElement ? locationAddressElement.textContent : '';

                                var locationPhoneElement = document.getElementById('locationPhone');
                                var locationPhone = locationPhoneElement ? locationPhoneElement.textContent : '';

                                var propertyLocationElement = document.getElementById('propertyLocation');
                                var propertyLocation = propertyLocationElement ? propertyLocationElement.textContent : '';

                                var heroImageElement = document.getElementById('pageHeroImage');
                                var heroImage = heroImageElement ? heroImageElement.textContent : '';

                                var datePublishedElement = document.getElementById('datePublished');
                                var datePublished = datePublishedElement ? parseInt(datePublishedElement.textContent) : 0;

                                var dateModifiedElement = document.getElementById('dateModified');
                                var dateModified = dateModifiedElement ? parseInt(dateModifiedElement.textContent) : 0;

                                var pageUrl = window.location.href;
                                pageUrl = pageUrl.split('?')[0];

                                var readTimeElement = document.getElementById('readTime');
                                var readTime = readTimeElement ? readTimeElement.textContent : '';

                                var longitudeElement = document.getElementById('longitude');
                                var longitude = longitudeElement ? longitudeElement.textContent : '';

                                var latitudeElement = document.getElementById('latitude');
                                var latitude = latitudeElement ? latitudeElement.textContent : '';

                                var introTextElement = document.getElementById('propertyIntroText');
                                var introText = introTextElement ? introTextElement.textContent : '';

                                var tagsElement = document.getElementById('tags');
                                var tags = tagsElement ? tagsElement.textContent : '';
                                var tagsArray = tags.split(",");

                                var locationAddressElement = document.getElementById('locationAddress');
                                var locationAddress = locationAddressElement ? locationAddressElement.textContent : '';

                                var locationPhoneElement = document.getElementById('locationPhone');
                                var locationPhone = locationPhoneElement ? locationPhoneElement.textContent : '';

                                var bedroomsElement = document.getElementById('propertyBedrooms');
                                var bedrooms = bedroomsElement ? bedroomsElement.textContent : '';

                                var bathroomsElement = document.getElementById('propertyBathrooms');
                                var bathrooms = bathroomsElement ? bathroomsElement.textContent : '';

                                var carspacesElement = document.getElementById('propertyCarspaces');
                                var carspaces = carspacesElement ? carspacesElement.textContent : '';

                                var vacanciesElement = document.getElementById('propertyVacancies');
                                var vacancies = vacanciesElement ? vacanciesElement.textContent : '';

                                var silRefElement = document.getElementById('silReference');
                                var silRef = silRefElement ? silRefElement.textContent : '';

                                var secTitleElement = document.getElementById('secTitle');
                                var secTitle = secTitleElement ? secTitleElement.textContent : '';

                                var propertyTypesElement = document.getElementById('propertyTypes');
                                var propertyTypes = propertyTypesElement ? propertyTypesElement.textContent : '';
                                var propertyTypesArray = propertyTypes.split(",");

                                var propertyStatesElement = document.getElementById('propertyStates');
                                var propertyStates = propertyStatesElement ? propertyStatesElement.textContent : '';
                                var propertyStatesArray = propertyStates.split(",");

                                var propertyFeaturesElement = document.getElementById('propertyFeatures');
                                var propertyFeatures = propertyFeaturesElement ? propertyFeaturesElement.textContent : '';
                                var propertyFeaturesArray = propertyFeatures.split(",");

                                var accessibilityFeaturesElement = document.getElementById('propertyAccessibilityFeatures');
                                var accessibilityFeatures = accessibilityFeaturesElement ? accessibilityFeaturesElement.textContent : '';
                                var accessibilityFeaturesArray = accessibilityFeatures.split(",");

                                var lifestyleFeaturesElement = document.getElementById('propertyLifestyleFeatures');
                                var lifestyleFeatures = lifestyleFeaturesElement ? lifestyleFeaturesElement.textContent : '';
                                var lifestyleFeaturesArray = lifestyleFeatures.split(",");

                                var additionalFeaturesElement = document.getElementById('propertyAdditionalFeatures');
                                var additionalFeatures = additionalFeaturesElement ? additionalFeaturesElement.textContent : '';
                                var additionalFeaturesArray = additionalFeatures.split(",");

                                var additionalFeaturesColourElement = document.getElementById('propertyAdditionalFeaturesColour');
                                var additionalFeaturesColour = additionalFeaturesColourElement ? additionalFeaturesColourElement.textContent : '';

                                var additionalFeaturesTitleElement = document.getElementById('propertyAdditionalFeaturesTitle');
                                var additionalFeaturesTitle = additionalFeaturesTitleElement ? additionalFeaturesTitleElement.textContent : '';

                                //console.log(propertyTypesArray);

                                if(readTime) {
                                    readTime = readTime.textContent;
                                } else {
                                    readTime = '';
                                }
                                var region = document.getElementById('propertyRegion');
                                if(region) {
                                    region = region.textContent;
                                } else {
                                    region = '';
                                }
                                /*var headingsTwo = document.getElementsByTagName("h2");
                                for (let i = 0; i < headingsTwo.length; i++) {
                                    headings += ' '+headingsTwo[i].textContent;
                                }
                                var paragraphs = document.getElementsByTagName("p");
                                for (let i = 0; i < paragraphs.length; i++) {
                                    headings += ' '+paragraphs[i].textContent;
                                }*/
                                document.querySelectorAll('.contentBlocks section').forEach( section => {
                                    //console.log(section);
                                    if(section.classList.contains('nextSteps') || section.classList.contains('searchSuggestions') || section.classList.contains('cta-module')) {

                                    } else {
                                        //console.log('test');
                                        var headingsTwo = section.querySelectorAll('h2');
                                        for (let i = 0; i < headingsTwo.length; i++) {
                                            bodyContent += ' '+headingsTwo[i].textContent;
                                        }
                                        var headingsThree = section.querySelectorAll('h3');
                                        for (let i = 0; i < headingsThree.length; i++) {
                                            bodyContent += ' '+headingsThree[i].textContent;
                                        }
                                        var headingsFour = section.querySelectorAll('h4');
                                        for (let i = 0; i < headingsFour.length; i++) {
                                            bodyContent += ' '+headingsFour[i].textContent;
                                        }
                                        var paragraphs = section.querySelectorAll("p");
                                        for (let i = 0; i < paragraphs.length; i++) {
                                            bodyContent += ' '+paragraphs[i].textContent;
                                        }
                                    }
                                });

                                if(postType == 'resources') {
                                    document.querySelectorAll('section.resourcesListing .container .resourcesOuter span.item span.desc').forEach( section => {
                                        bodyContent += ' '+section.textContent;
                                    });
                                }

                                var locationDescriptions = document.querySelectorAll("main.location section.banner span.textBlock .container span.inner p.description");
                                //console.log(locationDescriptions);
                                for (let i = 0; i < locationDescriptions.length; i++) {
                                    //console.log(locationDescriptions[i].textContent);
                                    locationDescription = locationDescriptions[i].textContent;
                                }

                                /*var locationAddressBlock = document.getElementById('locationAddress');
                                if(locationAddressBlock) {
                                    locationAddress = locationAddressBlock.textContent;
                                }

                                var locationPhoneBlock = document.getElementById('locationPhone');
                                if(locationPhoneBlock) {
                                    locationPhone = locationPhoneBlock.textContent;
                                }*/

                                //console.log(locationAddress);
                                //console.log(locationPhone);

                                //console.log(bodyContent);
                                //console.log(locationDescription);
                                //console.log(pageUrl);
                                //console.log(lang);
                                //console.log(postType);

                                /*if(postType == 'page' || postType == 'post' || postType == 'property') {*/
                                if(postType == 'page' || postType == 'post' || postType == 'property' || postType == 'location' || postType == 'resources') {
                                    let document = {
                                        'id': pageID,
                                        'post_type': postType,
                                        'permalink': pageUrl,
                                        'page_datetime': datePublished,
                                        'page_modified': dateModified,
                                        'page_title': pageTitle,
                                        'page_content': bodyContent,
                                        'short_description': introText,
                                        'read_time': readTime,
                                        'region': region,
                                        'hero_image': heroImage,
                                        'sil_reference': silRef,
                                        'secondary_title': secTitle,
                                        'longitude': longitude,
                                        'latitude': latitude,
                                        'tag': tagsArray,
                                        'location_address': locationAddress,
                                        'location_phone': locationPhone,
                                        'bedrooms': bedrooms,
                                        'bathrooms': bathrooms,
                                        'car_spaces': carspaces,
                                        'vacancies': vacancies,
                                        'property_type': propertyTypesArray,
                                        'property_feature': propertyFeaturesArray,
                                        'accessibility_feature': accessibilityFeaturesArray,
                                        'lifestyle_feature': lifestyleFeaturesArray,
                                        'supported_by': additionalFeaturesArray,
                                        'support_by_colour': additionalFeaturesColour,
                                        'support_by_title': additionalFeaturesTitle,
                                        'property_location': propertyLocation,
                                        'property_state': propertyStatesArray
                                    }
                                    var currentLang = Weglot.getCurrentLang();
                                    //console.log(currentLang);
                                    //console.log(lang);
                                    if(currentLang == 'es') {
                                        client.collections('pages-es').documents().upsert(document)
                                    } else if(currentLang == 'en') {
                                        client.collections('pages').documents().upsert(document)
                                    } else if(currentLang == 'it') {
                                        client.collections('pages-it').documents().upsert(document)
                                    } else if(currentLang == 'el') {
                                        client.collections('pages-el').documents().upsert(document)
                                    } else if(currentLang == 'fl') {
                                        client.collections('pages-fl').documents().upsert(document)
                                    } else if(currentLang == 'hi') {
                                        client.collections('pages-hi').documents().upsert(document)
                                    } else if(currentLang == 'vi') {
                                        client.collections('pages-vi').documents().upsert(document)
                                    } else if(currentLang == 'pa') {
                                        client.collections('pages-pa').documents().upsert(document)
                                    } else if(currentLang == 'ar') {
                                        client.collections('pages-ar').documents().upsert(document)
                                    }
                                }
                            }
                        }
                    }, 500);
                }
            });  
        }
        
    };

    document.head.appendChild(weglotScript);
    
    return () => {
        document.head.removeChild(weglotScript);
    };
};


export const onRouteUpdate = ({ location }) => {
  // Perform actions when the route updates
  // For example, you can trigger Typesense and Weglot updates here
  console.log('Route updated:', location.pathname);
  initializeWeGlot();
};